<template>
    <a-card title="收款信息" :bordered="false">
        <a-form-model class="form-model" layout="vertical" ref="form" :model="formField" :rules="formRules">
			<a-form-model-item label="收款人姓名（必须与基本信息一致）：">
				<div class="username">{{authUsername}}</div>
			</a-form-model-item>
			<a-form-model-item label="收款银行：" prop="bank">
                <a-select show-search style="width: 80%;max-width: 300px" v-model="formField.bank">
                    <a-select-option value="">请选择收款银行</a-select-option>
                    <a-select-option :value="name" v-for="(name, index) in bankArr" :key="index">
                    {{name}}
                    </a-select-option>
                </a-select>
			</a-form-model-item>
            <a-form-model-item label="开户行：" prop="bank_area">
				<a-input
                    style="width: 80%;max-width: 300px"
                    placeholder="例：建设银行厦门集美支行"
                    v-model="formField.bank_area"
                />
			</a-form-model-item>
            <a-form-model-item label="银行账号：" prop="bank_cart">
				<a-input
                    style="width: 80%;max-width: 300px"
                    placeholder="请填写银行账号"
                    v-model="formField.bank_cart"
                />
			</a-form-model-item>
			<a-form-model-item label=" " :colon="false">
				<a-button size="large" type="primary" :loading="loadBtn" :disabled="loadDisabled" @click="formSubmit">
					保存
				</a-button>
			</a-form-model-item>
		</a-form-model>
    </a-card>
</template>

<script>
import { mapState } from "vuex";
export default {
    components: {
	},
    computed: {
		...mapState({
			userInfo: (state) => state.user.user_info,
		}),
    },
    data() {
		return {
            bankArr: [
                '北京银行',
                '广发银行股份有限公司',
                '华夏银行',
                '交通银行',
                '宁波银行',
                '平安银行（原深圳发展银行）',
                '上海浦东发展银行',
                '上海银行',
                '兴业银行',
                '中国工商银行',
                '中国光大银行',
                '中国建设银行',
                '中国民生银行',
                '中国农业银行',
                '中国银行',
                '中国邮政储蓄银行',
                '中国招商银行',
                '中信银行',
                '福建省农村信用社联合社'
            ],
			formRules: {
                bank: [{ required: true, message: '请选择收款银行' }],
                bank_area: [{ required: true, message: '请填写开户行信息' }],
                bank_cart: [
                    { required: true, message: '请填写银行卡号' },
                    { pattern: /^\d{6,32}$/, message: '请检查卡号是否正确' },
                ],
            },
			formField: {
                bank: '',
                bank_area: '',
                bank_cart: '',
			},
            authUsername: '',
            modalErr: '',
			loadBtn: false,
            loadDisabled: false
		}
	},
	watch: {
		userInfo() {
			this.updateUserinfo();
		}
	},
	mounted() {
		this.updateUserinfo();
	},
	methods: {
		// 更新用户信息
		updateUserinfo() {
			let userInfo = this.$store.state.user.user_info;
            if(userInfo.auth_status == 1 && Object.prototype.toString.call(userInfo.auth_info) === '[object Object]') {
                this.authUsername = userInfo.auth_info.username;
            }
            if(Object.prototype.toString.call(userInfo.collection_info) === '[object Object]') {
                Object.assign(this.formField, {
                    username: userInfo.collection_info.reviewed.username,
                    bank: userInfo.collection_info.reviewed.bank,
                    bank_area: userInfo.collection_info.reviewed.bank_area,
                    bank_cart: userInfo.collection_info.reviewed.bank_cart,
                })
            }
            // 未同意协议
            if(userInfo.auth_status !== undefined && userInfo.auth_status != 1) {
                if(this.modalErr) return;
                this.modalErr = this.$modal.error({
                    title: '用户认证尚未通过',
                    content: '请确保认证通过，再来修改收款信息',
                    onOk: () =>{
                        this.loadDisabled = true;
                        this.$router.push({name: 'myInfo'});
                    },
                })
            }
		},
        // 同意用户协议
        authContract() {
            this.isContract = true;
            this.$api.authContract();
        },
		// 表单提交
		formSubmit() {
			this.$refs.form.validate(ispass => {
				if(ispass) {
					this.loadBtn = true;
                    this.loadDisabled = true;
                    this.$api.updateShoukuan({
                        extend: this.formField
                    }).then(res => {
                        if(res.status) {
                            this.$message.success({ content: '更新成功', key: 'message' });
                            this.$store.dispatch('user/getUserinfo');
                        }else{
                            this.$message.error({ content: res.msg, key: 'message' });
                            this.loadDisabled = false;
                        }
                    }).finally(() => {
                        this.loadBtn = false;
                    })
				}
      		});
		}
	}
}
</script>

<style lang="less" scoped>
.username{
    font-size: 30px;
}
</style>